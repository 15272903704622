<template>
  <!-- AI模拟面试第二页 -->
  <div class="AIInterviewReportTwo">
    <!-- 页顶 -->
    <ResumReportHeade :height="10"
                      report="AI面试报告" />
    <!-- 页顶 -->

    <!-- 问题回答解析 -->
    <div class="AnswerTheQuestionAnalysis">
      <div class="informationTop">
        <span class="mationImg"><img src="@/assets/image/resume/icon.png"
               alt="" /></span>
        <span class="mationText">问题回答解析</span>
      </div>
      <div class="AnswerTheQuestionAnalysisBox">
        <div class="AnswerTheQuestionLeft">
          <div class="LeftBoxOne">
            <div class="OneTitle">
              第一题：请你自我介绍一下。
              <span class="TitleSpan">(用时: 2分10秒)</span>
            </div>
            <div class="OneCentenIntroduce">
              <span class="IntroduceImg"><img src="@/assets/image/AIresume/icon(2).png"
                     alt="" /></span>
              您好,我叫刘予熙,今年25岁。毕业于桂林理工大学,所学专业是移动互联技术。大学四年通过不断的努力、锻炼、学习、实践和社会实习，在产品开发方面积累了丰富的经验。
            </div>
            <!-- Eaches图区域 -->
            <div class="OneBootmBox">
              <div class="OneBootmBoxLeft">
                <div class="EachesTitle">面试者心理状态</div>
                <div class="EachesAie">
                  <AIEchart :height="chartHeight"
                            :data="chartData"
                            :color="`#F08E3F`"
                            :roundColor="`#ffe7d2c6`" />
                </div>
              </div>
              <div class="OneBootmBoxRigth">
                <div class="manifestation">
                  <span class="Icon"><img src="@/assets/image/AIresume/icon(3).png"
                         alt="" /></span>
                  表现较好
                </div>
                <div class="IndividualDescription">
                  你能够简洁明了地跟面试官说出了基本信息，没有长篇大论，这样的回答很清晰，不错。
                </div>
              </div>
            </div>
          </div>
          <div class="LeftBoxOne">
            <div class="OneTitle">
              第一题：请你自我介绍一下。
              <span class="TitleSpan">(用时: 2分10秒)</span>
            </div>
            <div class="OneCentenIntroduce">
              <span class="IntroduceImg"><img src="@/assets/image/AIresume/icon(2).png"
                     alt="" /></span>
              您好,我叫刘予熙,今年25岁。毕业于桂林理工大学,所学专业是移动互联技术。大学四年通过不断的努力、锻炼、学习、实践和社会实习，在产品开发方面积累了丰富的经验。
            </div>
            <!-- Eaches图区域 -->
            <div class="OneBootmBox">
              <div class="OneBootmBoxLeft">
                <div class="EachesTitle">面试者心理状态</div>
                <div class="EachesAie">
                  <AIEchart :height="chartHeight"
                            :data="chartData" />
                </div>
              </div>
              <div class="OneBootmBoxRigth">
                <div class="manifestation">表现较好</div>
                <div class="IndividualDescription">
                  你能够简洁明了地跟面试官说出了基本信息，没有长篇大论，这样的回答很清晰，不错。
                </div>
              </div>
            </div>
          </div>
          <div class="LeftBoxOne">
            <div class="OneTitle">
              第一题：请你自我介绍一下。
              <span class="TitleSpan">(用时: 2分10秒)</span>
            </div>
            <div class="OneCentenIntroduce">
              <span class="IntroduceImg"><img src="@/assets/image/AIresume/icon(2).png"
                     alt="" /></span>
              您好,我叫刘予熙,今年25岁。毕业于桂林理工大学,所学专业是移动互联技术。大学四年通过不断的努力、锻炼、学习、实践和社会实习，在产品开发方面积累了丰富的经验。
            </div>
            <!-- Eaches图区域 -->
            <div class="OneBootmBox">
              <div class="OneBootmBoxLeft">
                <div class="EachesTitle">面试者心理状态</div>
                <div class="EachesAie">
                  <AIEchart :height="chartHeight"
                            :data="chartData" />
                </div>
              </div>
              <div class="OneBootmBoxRigth">
                <div class="manifestation">表现较好</div>
                <div class="IndividualDescription">
                  你能够简洁明了地跟面试官说出了基本信息，没有长篇大论，这样的回答很清晰，不错。
                </div>
              </div>
            </div>
          </div>
          <div class="LeftBoxOne">
            <div class="OneTitle">
              第一题：请你自我介绍一下。
              <span class="TitleSpan">(用时: 2分10秒)</span>
            </div>
            <div class="OneCentenIntroduce">
              <span class="IntroduceImg"><img src="@/assets/image/AIresume/icon(2).png"
                     alt="" /></span>
              您好,我叫刘予熙,今年25岁。毕业于桂林理工大学,所学专业是移动互联技术。大学四年通过不断的努力、锻炼、学习、实践和社会实习，在产品开发方面积累了丰富的经验。
            </div>
            <!-- Eaches图区域 -->
            <div class="OneBootmBox">
              <div class="OneBootmBoxLeft">
                <div class="EachesTitle">面试者心理状态</div>
                <div class="EachesAie">
                  <AIEchart :height="chartHeight"
                            :data="chartData" />
                </div>
              </div>
              <div class="OneBootmBoxRigth">
                <div class="manifestation">表现较好</div>
                <div class="IndividualDescription">
                  你能够简洁明了地跟面试官说出了基本信息，没有长篇大论，这样的回答很清晰，不错。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="BoxCentenSolid"></div>
        <div class="AnswerTheQuestionRigth">
          <div class="LeftBoxOne">
            <div class="OneTitle">
              第一题：请你自我介绍一下。
              <span class="TitleSpan">(用时: 2分10秒)</span>
            </div>
            <div class="OneCentenIntroduce">
              <span class="IntroduceImg"><img src="@/assets/image/AIresume/icon(2).png"
                     alt="" /></span>
              您好,我叫刘予熙,今年25岁。毕业于桂林理工大学,所学专业是移动互联技术。大学四年通过不断的努力、锻炼、学习、实践和社会实习，在产品开发方面积累了丰富的经验。
            </div>
            <!-- Eaches图区域 -->
            <div class="OneBootmBox">
              <div class="OneBootmBoxLeft">
                <div class="EachesTitle">面试者心理状态</div>
                <div class="EachesAie">
                  <AIEchart :height="chartHeight"
                            :data="chartData" />
                </div>
              </div>
              <div class="OneBootmBoxRigth">
                <div class="manifestation">
                  <span class="Icon"><img src="@/assets/image/AIresume/icon(3).png"
                         alt="" /></span>
                  表现较好
                </div>
                <div class="IndividualDescription">
                  你能够简洁明了地跟面试官说出了基本信息，没有长篇大论，这样的回答很清晰，不错。
                </div>
              </div>
            </div>
          </div>
          <div class="LeftBoxOne">
            <div class="OneTitle">
              第一题：请你自我介绍一下。
              <span class="TitleSpan">(用时: 2分10秒)</span>
            </div>
            <div class="OneCentenIntroduce">
              <span class="IntroduceImg"><img src="@/assets/image/AIresume/icon(2).png"
                     alt="" /></span>
              您好,我叫刘予熙,今年25岁。毕业于桂林理工大学,所学专业是移动互联技术。大学四年通过不断的努力、锻炼、学习、实践和社会实习，在产品开发方面积累了丰富的经验。
            </div>
            <!-- Eaches图区域 -->
            <div class="OneBootmBox">
              <div class="OneBootmBoxLeft">
                <div class="EachesTitle">面试者心理状态</div>
                <div class="EachesAie">
                  <AIEchart :height="chartHeight"
                            :data="chartData" />
                </div>
              </div>
              <div class="OneBootmBoxRigth">
                <div class="manifestation">表现较好</div>
                <div class="IndividualDescription">
                  你能够简洁明了地跟面试官说出了基本信息，没有长篇大论，这样的回答很清晰，不错。
                </div>
              </div>
            </div>
          </div>
          <div class="LeftBoxOne">
            <div class="OneTitle">
              第一题：请你自我介绍一下。
              <span class="TitleSpan">(用时: 2分10秒)</span>
            </div>
            <div class="OneCentenIntroduce">
              <span class="IntroduceImg"><img src="@/assets/image/AIresume/icon(2).png"
                     alt="" /></span>
              您好,我叫刘予熙,今年25岁。毕业于桂林理工大学,所学专业是移动互联技术。大学四年通过不断的努力、锻炼、学习、实践和社会实习，在产品开发方面积累了丰富的经验。
            </div>
            <!-- Eaches图区域 -->
            <div class="OneBootmBox">
              <div class="OneBootmBoxLeft">
                <div class="EachesTitle">面试者心理状态</div>
                <div class="EachesAie">
                  <AIEchart :height="chartHeight"
                            :data="chartData" />
                </div>
              </div>
              <div class="OneBootmBoxRigth">
                <div class="manifestation">表现较好</div>
                <div class="IndividualDescription">
                  你能够简洁明了地跟面试官说出了基本信息，没有长篇大论，这样的回答很清晰，不错。
                </div>
              </div>
            </div>
          </div>
          <div class="LeftBoxOne">
            <div class="OneTitle">
              整场用户面试心理情况
              <span class="TitleSpan">(用时: 2分10秒)</span>
            </div>
            <!-- Eaches图区域 -->
            <div class="OneBootmBoxEntirety">
              <div class="EachesAie">
                <AIEchart :height="OneBootmchartHeight"
                          :data="XchartData"
                          :xAxisLabels="xAxisLabels"
                          xName="分钟" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 问题回答解析 -->

    <!-- 第二页结尾 -->
    <ResumReportFoot :pages="`2/3`" />
    <!-- 第二页结尾 -->
  </div>
  <!-- AI模拟面试第二页 -->
</template>

<script>
import AIEchart from '@/components/AIEacharts' //折线图的组件
import ResumReportHeade from '@/components/ResumeReportHeader' //诊断报告的头部组件
import ResumReportFoot from '@/components/ResumeReportFoot' //诊断报告的脚部组件
export default {
  components: {
    AIEchart,
    ResumReportHeade,
    ResumReportFoot
  },
  data () {
    return {
      chartHeight: '130px',
      OneBootmchartHeight: '265px',
      xAxisLabels: ['0', '2', '4', '6', '8', '10', '12', '14'],
      XchartData: ['0', '2', '4', '6', '1', '10', '12', '18'],
      chartData: ['0', '50', '99']
    }
  },
  mounted () { },
  methods: {}
}
</script>

<style scoped lang="stylus">
.AIInterviewReportTwo
  .AnswerTheQuestionAnalysis
    box-sizing border-box
    margin-top 30px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 60px 34px 60px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .AnswerTheQuestionAnalysisBox
      width 998px
      height auto
      background #F1F3F7
      border-radius 5px
      display flex
      justify-content space-between
      padding 24px 30px 20px 30px
      .AnswerTheQuestionLeft
        .LeftBoxOne
          width 415px
          border-bottom 1px dashed #383A4D
          margin-bottom 15px
          padding-bottom 15px
          .OneTitle
            font-size 19px
            font-weight 500
            color #383A4D
            .TitleSpan
              font-size 14px
              font-weight 400
              color #878A99
          .OneCentenIntroduce
            margin-top 18px
            font-size 16px
            color #383A4D
            line-height 23px
            .IntroduceImg
              display inline-block
              width 15px
              height 15px
              img
                width 100%
                height 100%
          .OneBootmBox
            display flex
            margin-top 14px
            width 415px
            height 167px
            background #E4E7F0
            border-radius 10px
            padding 0 15px 0 15px
            .OneBootmBoxLeft
              width 207px
              height 100%
              padding-top 12px
              .EachesTitle
                font-size 16px
                color #383A4D
              .EachesAie
                width 190px
                height 130px
            .OneBootmBoxRigth
              width 207px
              height 100%
              padding 12px 16px 0 0
              .manifestation
                text-align center
                line-height 25px
                width 90px
                height 30px
                background #008AFF
                border-radius 3px
                color #F1F3F7
                .Icon
                  width 13px
                  height 13px
                  display inline-block
                  img
                    width 100%
                    height 100%
              .IndividualDescription
                margin-top 14px
                font-size 16px
                color #008AFF
                line-height 23px
      .AnswerTheQuestionRigth
        .LeftBoxOne
          width 415px
          border-bottom 1px dashed #383A4D
          margin-bottom 15px
          padding-bottom 15px
          .OneTitle
            font-size 19px
            font-weight 500
            color #383A4D
            .TitleSpan
              font-size 14px
              font-weight 400
              color #878A99
          .OneCentenIntroduce
            margin-top 18px
            font-size 16px
            color #383A4D
            line-height 23px
            .IntroduceImg
              display inline-block
              width 15px
              height 15px
              img
                width 100%
                height 100%
          .OneBootmBox
            display flex
            margin-top 14px
            width 415px
            height 167px
            background #E4E7F0
            border-radius 10px
            padding 0 15px 0 15px
            .OneBootmBoxLeft
              width 207px
              height 100%
              padding-top 12px
              .EachesTitle
                font-size 16px
                color #383A4D
              .EachesAie
                width 190px
                height 130px
            .OneBootmBoxRigth
              width 207px
              height 100%
              padding 12px 16px 0 0
              .manifestation
                text-align center
                line-height 25px
                width 90px
                height 30px
                background #008AFF
                border-radius 3px
                color #F1F3F7
                .Icon
                  width 13px
                  height 13px
                  display inline-block
                  img
                    width 100%
                    height 100%
              .IndividualDescription
                margin-top 14px
                font-size 16px
                color #008AFF
                line-height 23px
        .OneBootmBoxEntirety
          width 415px
          height 265px
          background #E4E7F0
          border-radius 5px
          margin-top 26px
          padding-left 10px
      .BoxCentenSolid
        width 1px
        height 1348px
        border 1px dashed #383A4D
</style>
