<template>
  <div ref="chart" :style="{ height: height }"></div>
</template>

<script>
require('echarts/lib/component/legend')
export default {
  name: 'EchartsLine',
  props: {
    height: {
      type: String,
      default: '130px'
    },
    data: {
      //折现图的数据
      type: Array,
      required: true
    },
    color: {
      //折线图曲线的颜色
      type: String
    },
    roundColor: {
      //折线图曲线小圆点的颜色
      type: String
    },
    yAxisLabels: {
      type: Array,
      default: () => ['', '忧郁', '平静', '激动']
    },
    xAxisLabels: {
      type: Array,
      default: () => ['0', '50秒', '1分20秒']
    },
    xName: {
      type: String
    }
  },
  mounted() {
    // 初始化 echarts 实例
    const chart = this.$echarts.init(this.$refs.chart)

    // 设置图表配置项
    const option = {
      grid: {
        x: 32,
        y: 30,
        x2: 40,
        y2: 25
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: this.xAxisLabels,
        name: this.xName,
        axisTick: {
          alignWithLabel: true, // 将刻度线和刻度标签对齐
          inside: true // 将刻度线移到轴线内部
        },
        axisLabel: {
          color: '#878A99' // 设置X轴文字颜色
        }
      },
      yAxis: {
        type: 'value',

        axisLine: {
          show: false,
          onZero: true // 将坐标轴原点放在y轴的0值上
        },
        axisTick: {
          alignWithLabel: true, // 将刻度线和刻度标签对齐
          show: false
        },
        splitLine: {
          show: true, // 显示分割线
          lineStyle: {
            color: '#ddd',
            width: 3,
            type: 'dashed'
          }
        },
        splitNumber: 3,
        axisLabel: {
          show: true, // 显示 y 轴标签
          color: '#878A99', // 设置y轴文字颜色
          formatter: (value, index) => {
            // 设置 y 轴标签格式化方式
            return this.yAxisLabels[index]
          }
        }
      },

      series: [
        {
          data: this.data,
          type: 'line',
          smooth: 1,
          ymbol: 'circle',
          lineStyle: {
            color: this.color
          },
          itemStyle: {
            color: this.color
          },

          areaStyle: {
            color: this.roundColor || 'rgba(135, 206, 235, 0.3)' // 设置淡蓝色
          }
        }
      ]
    }
    // 渲染图表
    chart.setOption(option)
  },
  render() {
    return <div ref='chart' style={{ height: this.height }}></div>
  }
}
</script>
