<template>
  <!-- AI模拟面试第三页 -->
  <div class="AIInterviewReportTwo">
    <!-- 页顶 -->
    <ResumReportHeade :height="10"
                      report="AI面试报告" />
    <!-- 页顶 -->

    <div class="ThreeOneTop">
      <!-- 答题表现 -->
      <div class="AnswerPerformance">
        <div class="informationTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png"
                 alt="" /></span>
          <span class="mationText">答题表现</span>
        </div>
        <!-- echarts图形区域 -->
        <div v-if="AnswerPerformance.categories.length"
             class="ImgVisual"
             style="width: auto; height: 260px"
             ref="main"></div>
        <div v-else
             class="elseImg"
             style="width: 400px; height: 230px">
          <img src="@/assets/image/no-data-icon1.png"
               alt="" />
          <div style="position: relative; left: 165px; bottom: 90px; font-size: 20px">暂无数据</div>
        </div>
      </div>
      <!-- 答题表现 -->

      <!-- 面试岗位匹配 -->
      <div class="InterviewPosition">
        <div class="informationTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png"
                 alt="" /></span>
          <span class="mationText">面试岗位匹配</span>
        </div>
        <div class="InterviewBoxList">
          <div class="ProgressOne"
               v-for="(item, index) in Progress"
               :key="index">
            <div class="ProgressOneText">
              <span>NO.{{ index + 1 }}</span>
              {{ item.ProgressOneText }}
            </div>
            <!-- <div class="BFB">{{ item.BFB + "%" }}</div> -->
            <div>
              <el-progress :percentage="item.BFB"
                           :text-inside="true"
                           :stroke-width="strokewidth"
                           text-color="#fff"></el-progress>
            </div>
          </div>
        </div>
      </div>
      <!-- 面试岗位匹配 -->
    </div>

    <div class="ThreeOneCenten">
      <!-- 改职位最近练习表现 -->
      <div class="PracticePerformance">
        <div class="informationTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png"
                 alt="" /></span>
          <span class="mationText">该职位最近练习表现</span>
        </div>
        <div class="PracticePerTop">
          <div class="Left">
            <span>{{ PracticePerformance.create_time }}</span>
            <span>本月最高{{ PracticePerformance.total_score }}分</span>
          </div>
          <div class="Rigth">
            {{ PracticePerformance.improve > 0 ? '同比上次提高' : '同比上次降低'
            }}{{ Math.abs(PracticePerformance.improve) }}%
            <!-- <img
              src="@/assets/image/AIresume/箭头.png"
              style="width: 15px; height: 18px"
            /> -->
          </div>
        </div>
        <!-- echarts图形区域 -->
        <div v-if="PracticePerformance.monthX.length"
             class="ImgVisual"
             style="width: auto; height: 220px"
             ref="chartOptions"></div>
        <div v-else
             class="elseImg"
             style="width: 400px; height: 180px">
          <img src="@/assets/image/no-data-icon1.png"
               alt="" />
          <div style="position: relative; left: 165px; bottom: 70px; font-size: 20px">暂无数据</div>
        </div>
      </div>
      <!-- 改职位最近练习表现 -->

      <!-- 同职位竞争力 -->
      <div class="Competitiveness">
        <div class="informationTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png"
                 alt="" /></span>
          <span class="mationText">同职位竞争力</span>
        </div>
        <div class="PracticePerTop">
          <div class="Left">
            <span>同练习对比</span>
            <span>{{ competitiveness.title }}</span>
          </div>
          <div class="Rigth">
            你的面试分数:
            {{ competitiveness.total_score ? competitiveness.total_score : 0 }}分
            <!-- <img
              src="@/assets/image/AIresume/箭头.png"
              style="width: 15px; height: 18px"
            /> -->
          </div>
        </div>
        <!-- echarts图形区域 -->
        <div v-if="competitiveness.competitivenessX.length"
             class="ImgVisual"
             style="width: auto; height: 220px"
             ref="chartHistogram"></div>
        <div v-else
             class="elseImg"
             style="width: 400px; height: 180px">
          <img src="@/assets/image/no-data-icon1.png"
               alt="" />
          <div style="position: relative; left: 165px; bottom: 70px; font-size: 20px">暂无数据</div>
        </div>
      </div>
      <!-- 同职位竞争力 -->
    </div>

    <!-- 评估维度 -->
    <div class="EvaluationDimension">
      <div class="informationTop">
        <span class="mationImg"><img src="@/assets/image/resume/icon.png"
               alt="" /></span>
        <span class="mationText">评估维度</span>
      </div>

      <div class="EvaluationToptext">
        该评估由面试有招——人才评估引擎，挖掘学生教育情况，家庭背景，过往经验结合企业等招聘情况分析得出，反应出候选人和企业所招岗位的匹配情况，为您做人才筛选提供权威的数据决策依据。
      </div>

      <div class="EvaluationLabelBox">
        <div class="LabelText"
             v-for="(item, index) in LabelTextLsit"
             :key="index"
             :style="{
            color: item.Textcolor,
            'background-color': item.BgColor,
            'border-color': item.Textcolor
          }">
          {{ item.LabelText }}
        </div>
      </div>
    </div>
    <!-- 评估维度 -->

    <div class="Database">
      <div>
        数据来源时间:
        <span>2022-12-11</span>
      </div>
      <div>
        报告生成时间:
        <span>{{ this.dataTmie }}</span>
      </div>
      <div>
        可参考周期:
        <span>一个季度</span>
      </div>
    </div>

    <div class="Reference">
      <div>
        <span class="referenceText">参考性:</span>
        该内容基于市场数据加工得来，仅供参考，该报告以辅助学生快速了解自身情况，系统根据用户的用户行为及表现给出的评估和判断，报告仅供参考，不予做任何肯定或否定的结论。
      </div>
    </div>

    <div class="DataSources">
      <div>
        <span class="referenceText">数据来源:</span>
        基于市场可产生数据结合模型分析得来
      </div>
    </div>

    <!-- 第三页结尾 -->
    <div class="Ending">
      <div class="EndingBox">
        <div class="EndingImg">
          <img src="@/assets/image/resume/二维码.png"
               alt="" />
        </div>
        <div class="EndingPape">
          <span class="SPan">-</span>
          <div class="report">广西梯子科技有限公司</div>
          <span class="SPan">-</span>
        </div>

        <div class="pageS">页码: 3/3</div>
      </div>
    </div>
    <!-- 第三页结尾 -->
  </div>
  <!-- AI模拟面试第三页 -->
</template>

<script>
import ResumReportHeade from '@/components/ResumeReportHeader' //诊断报告的头部组件
export default {
  components: {
    ResumReportHeade
  },
  props: {
    //答题表现折线图数据
    AnswerPerformance: {
      type: Object,
      default: {}
    },
    // 同职位竞争力折线图数据
    competitiveness: {
      type: Object,
      default: {}
    },
    // 该职位最近练习表现折线图数据
    PracticePerformance: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      dataTmie: '', //日期
      yAxisLabels: ['', '', '', '正常', '', '', ''], //答题表现Y轴替换文字
      ChartHistogramdataY: [], //同职位竞争力y轴
      strokewidth: 20, //进度条高度
      Progress: [
        //面试岗位匹配数据
        {
          ProgressOneText: '人事专员',
          BFB: 95
        },
        {
          ProgressOneText: '包装设计师',
          BFB: 92
        },
        {
          ProgressOneText: '客服',
          BFB: 90
        },
        {
          ProgressOneText: 'java前端开发',
          BFB: 88
        },
        {
          ProgressOneText: '会计',
          BFB: 87
        },
        {
          ProgressOneText: '产品经理',
          BFB: 86
        },
        {
          ProgressOneText: '软件开发',
          BFB: 80
        },
        {
          ProgressOneText: '财务',
          BFB: 75
        }
      ],
      LabelTextLsit: [
        //评估维度的数据
        {
          LabelText: '教育背景',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#E0F1FF'
        },
        {
          LabelText: '学历',
          Textcolor: '#1DC5C4', //和边框同色
          BgColor: '#e4f8f8'
        },
        {
          LabelText: '实习经历',
          Textcolor: '#70CE46 ', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '资格证书',
          Textcolor: '#FA561F', //和边框同色
          BgColor: '#feeae4'
        },
        {
          LabelText: '学校',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#fde9f4'
        },
        {
          LabelText: '专业',
          Textcolor: '#732ED1', //和边框同色
          BgColor: '#eee6f9'
        },
        {
          LabelText: '人脉',
          Textcolor: '#F74852', //和边框同色
          BgColor: '#fee9ea'
        },
        {
          LabelText: '工作经历',
          Textcolor: '#70CE46', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '教育背景',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#E0F1FF'
        },
        {
          LabelText: '学历',
          Textcolor: '#1DC5C4', //和边框同色
          BgColor: '#e4f8f8'
        },
        {
          LabelText: '实习经历',
          Textcolor: '#70CE46 ', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '资格证书',
          Textcolor: '#FA561F', //和边框同色
          BgColor: '#feeae4'
        },
        {
          LabelText: '学校',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#fde9f4'
        },
        {
          LabelText: '专业',
          Textcolor: '#732ED1', //和边框同色
          BgColor: '#eee6f9'
        },
        {
          LabelText: '人脉',
          Textcolor: '#F74852', //和边框同色
          BgColor: '#fee9ea'
        },
        {
          LabelText: '工作经历',
          Textcolor: '#70CE46', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '实习经历',
          Textcolor: '#70CE46 ', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '资格证书',
          Textcolor: '#FA561F', //和边框同色
          BgColor: '#feeae4'
        },
        {
          LabelText: '学校',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#fde9f4'
        },
        {
          LabelText: '专业',
          Textcolor: '#732ED1', //和边框同色
          BgColor: '#eee6f9'
        },
        {
          LabelText: '人脉',
          Textcolor: '#F74852', //和边框同色
          BgColor: '#fee9ea'
        },
        {
          LabelText: '工作经历',
          Textcolor: '#70CE46', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '实习经历',
          Textcolor: '#70CE46 ', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '资格证书',
          Textcolor: '#FA561F', //和边框同色
          BgColor: '#feeae4'
        },
        {
          LabelText: '学校',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#fde9f4'
        },
        {
          LabelText: '专业',
          Textcolor: '#732ED1', //和边框同色
          BgColor: '#eee6f9'
        },
        {
          LabelText: '人脉',
          Textcolor: '#F74852', //和边框同色
          BgColor: '#fee9ea'
        },
        {
          LabelText: '工作经历',
          Textcolor: '#70CE46', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '实习经历',
          Textcolor: '#70CE46 ', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '资格证书',
          Textcolor: '#FA561F', //和边框同色
          BgColor: '#feeae4'
        },
        {
          LabelText: '学校',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#fde9f4'
        },
        {
          LabelText: '专业',
          Textcolor: '#732ED1', //和边框同色
          BgColor: '#eee6f9'
        },
        {
          LabelText: '人脉',
          Textcolor: '#F74852', //和边框同色
          BgColor: '#fee9ea'
        },
        {
          LabelText: '工作经历',
          Textcolor: '#70CE46', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '实习经历',
          Textcolor: '#70CE46 ', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '资格证书',
          Textcolor: '#FA561F', //和边框同色
          BgColor: '#feeae4'
        },
        {
          LabelText: '学校',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#fde9f4'
        },
        {
          LabelText: '专业',
          Textcolor: '#732ED1', //和边框同色
          BgColor: '#eee6f9'
        },
        {
          LabelText: '人脉',
          Textcolor: '#F74852', //和边框同色
          BgColor: '#fee9ea'
        },
        {
          LabelText: '工作经历',
          Textcolor: '#70CE46', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '实习经历',
          Textcolor: '#70CE46 ', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '资格证书',
          Textcolor: '#FA561F', //和边框同色
          BgColor: '#feeae4'
        },
        {
          LabelText: '学校',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#fde9f4'
        },
        {
          LabelText: '专业',
          Textcolor: '#732ED1', //和边框同色
          BgColor: '#eee6f9'
        },
        {
          LabelText: '人脉',
          Textcolor: '#F74852', //和边框同色
          BgColor: '#fee9ea'
        },
        {
          LabelText: '工作经历',
          Textcolor: '#70CE46', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '实习经历',
          Textcolor: '#70CE46 ', //和边框同色
          BgColor: '#eef9e9'
        },
        {
          LabelText: '资格证书',
          Textcolor: '#FA561F', //和边框同色
          BgColor: '#feeae4'
        },
        {
          LabelText: '学校',
          Textcolor: '#008AFF', //和边框同色
          BgColor: '#fde9f4'
        },
        {
          LabelText: '专业',
          Textcolor: '#732ED1', //和边框同色
          BgColor: '#eee6f9'
        },
        {
          LabelText: '人脉',
          Textcolor: '#F74852', //和边框同色
          BgColor: '#fee9ea'
        },
        {
          LabelText: '工作经历',
          Textcolor: '#70CE46', //和边框同色
          BgColor: '#eef9e9'
        }
      ]
    }
  },
  mounted () {
    this.competitivenessY()
    if (this.AnswerPerformance.categories.length) this.echartsInit()
    if (this.PracticePerformance.monthX.length) this.chartOptions()
    if (this.competitiveness.competitivenessX.length) this.chartHistogram()
    this.TimeData()
  },
  methods: {
    // 同职位竞争力Y轴数据处理
    competitivenessY () {
      if (this.competitiveness.competitivenessY.length) {
        this.competitiveness.competitivenessY.forEach(item => {
          this.ChartHistogramdataY.push(item.num)
        })
      }
    },
    //答题表现Echarts折现图
    echartsInit () {
      const chart = this.$echarts.init(this.$refs.main)

      // 设置图表配置项
      const option = {
        grid: {
          x: 40,
          y: 20,
          x2: 35,
          y2: 60
        },
        xAxis: {
          type: 'category',
          data: this.AnswerPerformance.categories,
          axisTick: {
            alignWithLabel: true, // 将刻度线和刻度标签对齐
            inside: true // 将刻度线移到轴线内部
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 100,
          axisLine: {
            show: false // 隐藏Y轴轴线
          },
          axisTick: {
            alignWithLabel: true, // 将刻度线和刻度标签对齐
            show: false
          },
          splitLine: {
            show: true, // 显示分割线
            lineStyle: {
              color: '#ddd',
              width: 2,
              type: 'dashed'
            }
          },
          axisLabel: {
            color: '#878A99', // 设置y轴文字颜色
            formatter: (value, index) => {
              // 设置 y 轴标签格式化方式
              return this.yAxisLabels[index]
            }
          }
        },
        series: [
          {
            data: this.AnswerPerformance.series[0].data,
            type: 'line',
            smooth: 0.5,
            areaStyle: {
              color: 'rgba(135, 206, 235, 0.3)' // 设置淡蓝色
            }
          }
        ]
      }
      // 渲染图表
      chart.setOption(option)
    },
    //改职位最近练习表现Echarts图
    chartOptions () {
      //答题表现的echarts图
      const chart = this.$echarts.init(this.$refs.chartOptions)
      const option = {
        grid: {
          x: 45,
          y: 20,
          x2: 35,
          y2: 70
        },
        xAxis: {
          type: 'category',
          data: this.PracticePerformance.monthX,
          axisTick: {
            alignWithLabel: true, // 将刻度线和刻度标签对齐
            inside: true // 将刻度线移到轴线内部
          },
          axisLabel: {
            color: '#878A99' // 设置X轴文字颜色
          },
          axisLabel: {
            color: '#878A99' // 设置X轴文字颜色
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 100,
          axisTick: {
            alignWithLabel: true, // 将刻度线和刻度标签对齐
            show: false
          },
          axisLine: {
            show: false // 隐藏Y轴轴线
          },
          splitLine: {
            show: true, // 显示分割线
            lineStyle: {
              color: '#ddd',
              width: 2,
              type: 'dashed'
            }
          },
          axisLabel: {
            color: '#878A99', // 设置y轴文字颜色
            formatter: function (value) {
              return `${value} 分`
            }
          }
        },
        series: [
          {
            data: this.PracticePerformance.monthY,
            type: 'line',
            label: {
              // 添加数据标签
              show: true, // 显示数据标签
              formatter: params => {
                return `${params.value}分` // 设置数据标签格式化方式
              },
              color: '#0485F3', // 数据标签颜色
              fontSize: 12, // 数据标签字体大小
              position: 'top' // 数据标签位置
            },
            smooth: 0.5,
            areaStyle: {
              color: 'rgba(135, 206, 235, 0.3)' // 设置淡蓝色
            }
          }
        ]
      }

      chart.setOption(option)
    },
    //同职位竞争力Echarts图
    chartHistogram () {
      const chart = this.$echarts.init(this.$refs.chartHistogram)
      const option = {
        grid: {
          x: 0,
          y: 15,
          x2: 0,
          y2: 70
        },
        xAxis: {
          type: 'category',
          data: this.competitiveness.competitivenessX,
          axisLine: {
            show: false // 隐藏x轴线
          },
          axisTick: {
            show: false // 隐藏x轴刻度线
          },
          axisLabel: {
            color: '#666' // 设置x轴标签颜色
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 100
        },
        series: [
          {
            type: 'bar',
            data: this.ChartHistogramdataY,
            barWidth: 20, // 设置柱子宽度
            itemStyle: {
              color: '#3e97d1'
            },
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      chart.setOption(option)
    },
    //日期格式处理
    TimeData () {
      //年
      let year = new Date().getFullYear()
      //月份是从0月开始获取的，所以要+1;
      let month = new Date().getMonth() + 1
      //日
      let day = new Date().getDate()
      if (month < 10) month = '0' + month
      this.dataTmie = year + '-' + month + '-' + day
    }
  }
}
</script>

<style scoped lang="stylus">
.AIInterviewReportTwo
  .ThreeOneTop
    margin-top 30px
    display flex
    justify-content space-between
    .AnswerPerformance
      width 514px
      height 285px
      border 1px solid #383A4D
      padding 26px 35px 25px 35px
      .informationTop
        display flex
        align-items center
        .mationImg
          width 15px
          height 15px
          margin-right 8px
          img
            width 100%
            height 100%
        .mationText
          font-size 21px
          font-weight bold
          color #383A4D
    .InterviewPosition
      width 514px
      height 285px
      border 1px solid #383A4D
      padding 26px 35px 0px 35px
      .informationTop
        display flex
        align-items center
        .mationImg
          width 15px
          height 15px
          margin-right 8px
          img
            width 100%
            height 100%
        .mationText
          font-size 21px
          font-weight bold
          color #383A4D
      .InterviewBoxList
        margin-top 20px
        display flex
        justify-content space-between
        flex-wrap wrap
        .ProgressOne
          width 48%
          margin-bottom 10px
          position relative
          .el-progress-bar__innerText
            color #fff
          .ProgressOneText
            font-size 14px
            font-weight 500
            color #383A4D
            margin-bottom 3px
            span
              font-size 14px
              font-weight 500
              color #0285F5
          .BFB
            font-size 14px
            font-weight 500
            color #383A4D
            position absolute
            top 0
            right 15px
  .ThreeOneCenten
    margin-top 30px
    display flex
    justify-content space-between
    .PracticePerformance
      width 514px
      height 285px
      border 1px solid #383A4D
      padding 26px 35px 25px 35px
      .informationTop
        display flex
        align-items center
        .mationImg
          width 15px
          height 15px
          margin-right 8px
          img
            width 100%
            height 100%
        .mationText
          font-size 21px
          font-weight bold
          color #383A4D
      .PracticePerTop
        padding 0 10px 0 10px
        margin-top 20px
        width 420px
        height 32px
        line-height 32px
        border-radius 5px
        background #E5E6E9
        display flex
        justify-content space-between
        .Left
          & span
            font-size 16px
            font-weight 400
            color #383A4D
            margin-right 12px
        .Rigth
          display flex
          align-items center
          font-size 16px
          font-weight 400
          color #008AFF
          white-space nowrap
    .Competitiveness
      width 514px
      height 285px
      border 1px solid #383A4D
      padding 26px 35px 25px 35px
      .informationTop
        display flex
        align-items center
        .mationImg
          width 15px
          height 15px
          margin-right 8px
          img
            width 100%
            height 100%
        .mationText
          font-size 21px
          font-weight bold
          color #383A4D
      .PracticePerTop
        padding 0 10px 0 10px
        margin-top 20px
        width 420px
        height 32px
        line-height 32px
        border-radius 5px
        background #E5E6E9
        display flex
        justify-content space-between
        .Left
          & span
            font-size 16px
            font-weight 400
            color #383A4D
            margin-right 16px
        .Rigth
          display flex
          align-items center
          font-size 16px
          font-weight 400
          color #008AFF
          white-space nowrap
  .EvaluationDimension
    box-sizing border-box
    margin-top 30px
    width 1118px
    height auto
    border 1px solid #383a4d
    border-radius 8px
    padding 26px 60px 34px 60px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .EvaluationToptext
      font-size 16px
      color #383A4D
      line-height 29px
    .EvaluationLabelBox
      display flex
      flex-wrap wrap
      margin-top 10px
      .LabelText
        text-align center
        margin-right 10px
        margin-bottom 10px
        box-sizing border-box
        padding 8px 15px
        border 1px solid #008AFF
        background rgba(0, 138, 255, 0.12)
        color #008AFF
  .Database
    width 60%
    margin-top 46px
    display flex
    justify-content space-between
    & div
      font-size 16px
      font-weight bold
      color #383A4D
      & span
        margin-left 3px
        font-size 16px
        font-weight bold
        color rgb(135, 138, 153)
  .Reference
    margin-top 23px
    div
      margin-left 3px
      font-size 16px
      font-weight bold
      color rgb(135, 138, 153)
      line-height 29px
    .referenceText
      font-size 16px
      font-weight bold
      color #383A4D
  .DataSources
    margin-top 23px
    div
      margin-left 3px
      font-size 16px
      font-weight bold
      color rgb(135, 138, 153)
      line-height 29px
    .referenceText
      font-size 16px
      font-weight bold
      color #383A4D
  .Ending
    border-top 1px dashed #383A4D
    height auto
    margin-top 280px
    .EndingImg
      width 65px
      height 65px
      img
        width 100%
        height 100%
    .EndingBox
      display flex
      align-items center
      justify-content space-between
      margin-top 15px
      .EndingPape
        display flex
        justify-content center
        .SPan
          font-size 18px
          line-height 20px
          color #878A99
        .report
          font-size 16px
          font-weight 400
          color #878A99
          margin 0 8px
      .pageS
        font-size 16px
        font-weight 400
        color #878A99
</style>
