<template>
  <div>
    <!-- 版心 -->
    <div class="typeArea"
         id="print-content">
      <!-- 页顶 -->
      <ResumReportHeade report="AI面试报告" />
      <!-- 页顶 -->

      <!-- 基础信息 -->
      <basicInformationComponent />
      <!-- 基础信息 -->

      <!--评估分数-->
      <div class="ScorimgData">
        <div class="ScorImgFlexLeft">
          <div class="ScorimgDataTop">
            <span class="mationImg"><img src="@/assets/image/resume/icon.png"
                   alt="" /></span>
            <span class="mationText">评估分数</span>
          </div>
          <!-- 左侧图片区域 -->
          <div class="ScorImgDataLeft">
            <div class="LeftTopText">
              {{ oItem.total_score || '0' }}
              <span>分</span>
            </div>
            <div class="AIScore">AI面试综合评分</div>
            <div class="LeftCentnText">
              面试岗位:
              <span v-if="oItem.info">{{ oItem.info.title }}</span>
            </div>
            <div class="LeftCentnText">
              面试提数:
              <span v-if="oItem.answer_score_list">共{{ oItem.answer_score_list.length || '0' }}题</span>
            </div>
            <div class="LeftCentnText">
              面试时长:
              <span>{{ allMin }}分{{ allSecond }}秒</span>
            </div>
            <div class="LeftCentnRanking">
              <div class="LeftCentnRanImgBox">
                <img src="@/assets/image/AIresume/icon1.1.png"
                     alt="" />
              </div>
              <div class="RanKingText">全校排名: 第1000名</div>
            </div>
            <div class="LeftBootomText">本次面试击败同职位{{ oItem.competitiveness_abroad }}%面试者</div>
          </div>
          <!-- 左侧图片区域 -->
        </div>
        <div class="ScorRigthBox">
          <div class="ScorRingthBoxTop">
            <!-- 圆形图部分 -->
            <div class="MajorOne"
                 v-for="(item, index) in OneProgressLisy"
                 :key="index">
              <div class="OneProgress">
                <el-progress :color="item.color"
                             type="circle"
                             :percentage="item.percentage"
                             :show-text="false"></el-progress>
                <div class="text_box"
                     :style="{ borderColor: item.color }">
                  <p class="title"
                     :style="{ color: item.color }">
                    {{ item.format }}
                  </p>
                </div>
              </div>
              <!-- 文字部分 -->
              <div class="OneProgressText">
                <div class="Specialized">{{ item.Specialized }}</div>
                <div class="Textpercentage">
                  {{ item.Textpercentage }}
                  <span class="Fen">分</span>
                </div>
              </div>
            </div>
            <!-- 圆形图部分 -->
            <div class="MannerFour">
              <div class="MannerFourTop">
                <div class="TopLeftImg">
                  <img src="@/assets/image/AIresume/icon.png"
                       alt="" />
                </div>
                <div class="TopRigthBox">
                  <div class="RigthNumber">92.05</div>
                  <div class="RigthText">心理指数</div>
                </div>
              </div>
              <div class="MannerFuurBootom">
                <!-- 进度条 -->
                <div class="FuurTop">
                  <div class="progress-bar">
                    <!-- 动态进度 -->
                    <div class="progress"
                         style="height: 40%"></div>
                  </div>
                  <div class="FuurTopRigth">
                    <div class="minute">102字/分钟</div>
                    <div class="speed">语速</div>
                  </div>
                </div>
                <!-- 第二段分割 -->
                <div class="FuurTwo">
                  <div class="FuurTop">
                    <div class="progress-bar">
                      <!-- 动态进度 -->
                      <div class="progress"
                           style="height: 40%"></div>
                    </div>
                    <div class="FuurTopRigth">
                      <div class="minute">正常</div>
                      <div class="speed">流畅度</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Microphone">
                <img src="@/assets/image/AIresume/icon(1).png"
                     alt="" />
              </div>
            </div>
          </div>
          <div class="ScorRingthBoxBottom">
            该评估由面试有招——人才评估引擎,基于海量人才数据、企业招聘数据、行业人才数据等情况结合人工智能算法分析得出,能准确的反应出候选人和企业所招岗位的匹配情况,为您做人才筛选提供权威的数据决策依据。
          </div>
        </div>
      </div>
      <!--评估分数 -->

      <!-- 各项能力指标分析 -->
      <div class="AbilityIndicator question-table">
        <div class="informationTop">
          <span class="mationImg"><img src="@/assets/image/resume/icon.png"
                 alt="" /></span>
          <span class="mationText">各项能力指标分析</span>
        </div>
        <div class="AbilityIndicatorBox">
          <div class="AbilityIndicatorLeft"
               v-if="radars.categories.length">
            <div class="CapabilityRadar">能力雷达图</div>
            <div class="ImgVisual"
                 style="width: auto; height: 400px"
                 id="main"></div>
          </div>
          <div class="AbilityIndicatorLeft"
               v-else
               style="width: auto; height: 400px">
            <img src="@/assets/image/no-data-icon1.png"
                 alt="" />
            <div style="position: relative; left: 208px; bottom: 130px; font-size: 20px">暂无数据</div>
          </div>
          <div class="AbilityIndicatorRigth">
            <div class="AbilityIndicatorRigthTop">
              <div class="Title">表现较好的能力</div>
              <div class="AbilityList"
                   v-if="radarData.radarGood.length">
                <div class="ListEach"
                     v-for="(item, index) in radarData.radarGood"
                     :key="index">
                  {{ item }}
                </div>
              </div>
              <div v-else
                   class="AbilityList">无</div>
            </div>
            <div class="AbilityIndicatorRigthBootom">
              <div class="Title">有待提高的能力</div>
              <div class="AbilityList"
                   v-if="radarData.radarBad.length">
                <div class="ListEach"
                     v-for="(item, index) in radarData.radarBad"
                     :key="index">
                  {{ item }}
                </div>
              </div>
              <div class="AbilityList"
                   v-else>无</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 各项能力指标分析 -->

      <div class="BootomFlexBox question-table">
        <!-- 综合表现 -->
        <div class="OverallBestAward">
          <div class="informationTop">
            <span class="mationImg"><img src="@/assets/image/resume/icon.png"
                   alt="" /></span>
            <span class="mationText">综合表现</span>
          </div>
          <div class="OverallBestAwardList">
            <div class="OverallBestAwardListOne"
                 v-for="(item, index) in OverallBestAwardList"
                 :key="index"
                 :style="{ borderColor: item.color }">
              <div class="TopImg">
                <img :src="item.Img"
                     alt="" />
              </div>
              <div class="TopText">
                {{ item.text }}
              </div>
              <!-- 底部百分百 -->
              <div class="percentage"
                   :style="{ color: item.color, background: item.Bgcolor }">
                {{ item.percentage }}
              </div>
            </div>
          </div>
        </div>
        <!-- 综合表现 -->

        <!-- 能力分析 -->
        <div class="AbilityAnalysis">
          <div class="informationTop">
            <span class="mationImg"><img src="@/assets/image/resume/icon.png"
                   alt="" /></span>
            <span class="mationText">能力分析</span>
          </div>
          <div class="OverallBestAwardList">
            <div class="OverallBestAwardListOne"
                 v-for="(item, index) in AbilityAnalysisList"
                 :key="index"
                 :style="{ borderColor: item.color }">
              <div class="TopImg">
                <img :src="item.Img"
                     alt="" />
              </div>
              <div class="TopText">
                {{ item.text }}
              </div>
              <!-- 底部百分百 -->
              <div class="percentage"
                   :style="{ color: item.color, background: item.Bgcolor }">
                {{ item.percentage }}
              </div>
            </div>
          </div>
        </div>
        <!-- 能力分析 -->
      </div>

      <!-- 第一页结尾 -->
      <ResumReportFoot :pages="`1/3`"
                       class="question-table" />
      <!-- 第一页结尾 -->

      <!-- AI诊断报告第二页 -->
      <AIInterviewReportTwo class="question-table" />
      <!-- AI诊断报告第二页 -->

      <!-- AI诊断报告第三页 -->
      <AIInterviewReportThree :AnswerPerformance="AnswerPerformance"
                              :competitiveness="competitiveness"
                              :PracticePerformance="PracticePerformance"
                              v-if="isShow" />
      <!-- AI诊断报告第三页 -->
    </div>
    <!-- 版心 -->
    <el-button class="acticedBtn"
               @click="printFn"
               type="primary">一键导出</el-button>
  </div>
</template>

<script>
import PdfLoader from '@/utils/htmlpdf.js'
import AIInterviewReportTwo from './AIInterviewReportTwo/index.vue' //AI模拟诊断第二页
import AIInterviewReportThree from './AIInterviewReportThree/index.vue' //AI模拟诊断第三页
import ResumReportHeade from '@/components/ResumeReportHeader' //诊断报告的头部组件
import ResumReportFoot from '@/components/ResumeReportFoot' //诊断报告的脚部组件
import basicInformationComponent from '@/components/basicInformationComponent.vue' //基础信息组件
import { mapActions } from 'vuex'
export default {
  name: 'ResumeDiagnosticReport', //简历诊断报告详情页面
  components: {
    AIInterviewReportTwo,
    AIInterviewReportThree,
    ResumReportHeade,
    ResumReportFoot,
    basicInformationComponent
  },
  data () {
    return {
      allMin: '', //分
      allSecond: '', //秒
      isShow: false, //控制子组件的渲染
      AnswerPerformance: {}, //答题表现折线图数据
      //同职位竞争力柱状图数据
      competitiveness: {},
      // 该职位最近练习表现
      PracticePerformance: {},
      oItem: {}, // 我训练详情数据
      radarData: {
        radarGood: [], //表现较好的能力数据
        radarBad: [], //有待提高的能力数据
        answerBad: []
      },
      OneProgressLisy: [], //评估分数的进度条数据
      OverallBestAwardList: [
        {
          Img: require('@/assets/image/AIresume/1.png'),
          text: '态度表现',
          percentage: '75%',
          Bgcolor: '#e0f1ff',
          color: '#008AFF'
        },
        {
          Img: require('@/assets/image/AIresume/2.png'),
          text: '问题回答',
          percentage: '68%',
          Bgcolor: '#e5f8f4',
          color: '#29C7A6'
        },
        {
          Img: require('@/assets/image/AIresume/3.png'),
          text: '专业知识',
          percentage: '93%',
          Bgcolor: '#fef4ea',
          color: '#F9A755'
        },
        {
          Img: require('@/assets/image/AIresume/4.png'),
          text: '岗位匹配度',
          percentage: '80%',
          Bgcolor: '#f3f2ff',
          color: '#9D91FD'
        }
      ],
      AbilityAnalysisList: [
        {
          Img: require('@/assets/image/AIresume/1(1).png'),
          text: '职场经验',
          percentage: '75%',
          color: '#008AFF'
        },
        {
          Img: require('@/assets/image/AIresume/2(1).png'),
          text: '项目经验',
          percentage: '68%',
          color: '#F9A755'
        },
        {
          Img: require('@/assets/image/AIresume/3(1).png'),
          text: '管理经验',
          percentage: '93%',
          color: '#3FC7F4'
        },
        {
          Img: require('@/assets/image/AIresume/4(1).png'),
          text: '社会背景',
          percentage: '80%',
          color: '#008AFF'
        }
      ],
      radars: { categories: [], series: [{ data: [] }] }, //答题能力指标分析雷达图数据
      echartsInit () {
        // 初始化echats
        this.$echarts.init(document.getElementById('main')).setOption({
          color: ['#67F9D8', '#FFE434', '#56A3F1', '#FF917C'],
          legend: {},
          radar: [
            {
              indicator: [...this.radars.categories],

              radius: 150,
              startAngle: 90,
              splitNumber: 3,
              shape: 'circle',
              axisName: {
                formatter: '{value}',
                color: '#383A4D'
              },
              splitArea: {
                areaStyle: {
                  color: ['#FFF', '#FFF', '#FFF'],
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowBlur: 10
                }
              }
            }
          ],
          series: [
            {
              type: 'radar',
              data: [
                {
                  value: [...this.radars.series[0].data],
                  areaStyle: {
                    color: 'rgba(187, 212, 254)'
                  }
                }
              ]
            }
          ]
        })
      }
    }
  },
  async mounted () {
    await this.getInterviewTrainDetailFn()
    await this.changeDataFn()
    if (this.radars.categories.length) this.echartsInit()
    this.isShow = true
  },
  methods: {
    ...mapActions(['getInterviewTrainDetail']),
    // 获取我训练详情
    async getInterviewTrainDetailFn () {
      this.oItem = await this.getInterviewTrainDetail({
        answer_id: '1678358183115417'
      })
    },
    // 处理单位数字的函数
    showNum (num) {
      if (num < 10) {
        return '0' + num
      }
      return num
    },
    //在获取训练详情后的赋值方法
    changeDataFn () {
      //评估分数的进度条数据
      this.OneProgressLisy = [
        {
          percentage: this.oItem.score_compose.representation.score,
          format: '语言',
          Specialized: '语言描述',
          Textpercentage: this.oItem.score_compose.representation.score || '0',
          color: '#F7D42D'
        },
        {
          percentage: this.oItem.score_compose.profession.score,
          format: '专业',
          Specialized: '专业知识',
          Textpercentage: this.oItem.score_compose.profession.score || '0',
          color: '#F9994B'
        },
        {
          percentage: this.oItem.score_compose.manner.score,
          format: '态度',
          Specialized: '面试态度',
          Textpercentage: this.oItem.score_compose.manner.score || '0',
          color: '#2BE4C3'
        }
      ]
      //表现较好的能力数据
      this.radarData.radarGood = this.oItem.radar_good
      //有待提高的能力数据
      this.radarData.radarBad = this.oItem.radar_bad
      // 答题表现折线图数据
      this.AnswerPerformance = {
        categories: this.oItem.pepresentation.x ? this.oItem.pepresentation.x : [],
        series: [
          {
            data: this.oItem.pepresentation.y ? this.oItem.pepresentation.y : [],
            color: '#ffdd38'
          }
        ]
      }
      // 该职位最近练习表现数据
      this.PracticePerformance = {
        create_time: this.oItem.laslately.create_time ? this.oItem.laslately.create_time : '--',
        total_score: this.oItem.laslately.total_score,
        improve: this.oItem.laslately.improve ? this.oItem.laslately.improve : '',
        monthX: this.oItem.history.week.x ? this.oItem.history.week.x : [],
        monthY: this.oItem.history.week.y ? this.oItem.history.week.y : []
      }
      // 同岗位竞争力数据
      this.competitiveness = {
        title: this.oItem.info.title ? this.oItem.info.title : '--',
        total_score: this.oItem.total_score ? this.oItem.total_score : '--',
        competitivenessX: this.oItem.competitiveness.x ? this.oItem.competitiveness.x : [],
        competitivenessY: this.oItem.competitiveness.y ? this.oItem.competitiveness.y : []
      }
      //答题能力指标分析雷达图数据
      this.radarsEchart()
      this.allMin = this.showNum(parseInt(this.oItem.time_consuming / 60) % 60)
      this.allSecond = this.showNum(this.oItem.time_consuming % 60)
    },
    // 答题能力指标分析雷达图数据处理
    radarsEchart () {
      if (this.oItem.radar.length) {
        this.oItem.radar.forEach(item => {
          this.radars.categories.push({ text: item.ability_name, max: 100 })
          this.radars.series[0].data.push(item.score)
        })
      }
    },
    printFn () {
      const pdf = document.getElementById('print-content') // 需要导出部分页面的id名
      this.pdfDownLoader = new PdfLoader(pdf, 'fileName', 'question-table') // fileName -->导出文件名,  question-table -->防止被截断的class名
      this.pdfDownLoader.outPutPdfFn('AI面试报告')
    }
  }
}
</script>

<style scoped lang="stylus">
.typeArea
  box-sizing border-box
  width 1240px
  margin 0 auto
  padding 0px 61px 0 61px
  height auto
  .ScorimgData
    display flex
    box-sizing border-box
    margin-top 19px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 35px 25px 35px
    .ScorImgFlexLeft
      margin-right 38px
      .ScorimgDataTop
        display flex
        align-items center
        margin-bottom 20px
        .mationImg
          width 15px
          height 15px
          margin-right 8px
          img
            width 100%
            height 100%
        .mationText
          font-size 21px
          font-weight bold
          color #383A4D
      .ScorImgDataLeft
        width 250px
        height 305px
        background linear-gradient(60deg, #003DBB 0%, #3BB8EB 100%)
        border-radius 6px
        background url('~@/assets/image/AIresume/bg.png') no-repeat
        background-size 100% 100%
        .LeftTopText
          font-size 60px
          font-weight 500
          color #fff
          margin-left 23px
          span
            margin-left 3px
            display inline-block
            font-size 23px
            font-weight 500
            color #fff
        .AIScore
          margin-left 23px
          font-size 22px
          font-weight 500
          margin-bottom 10px
          color #fff
        .LeftCentnText
          line-height 29px
          margin-left 23px
          font-size 19px
          color rgb(184, 189, 237)
          span
            color #fff
        .LeftCentnRanking
          display flex
          align-items center
          margin-top 10px
          margin-left 13px
          width 224px
          height 45px
          background rgba(0, 0, 0, 0.2)
          border-radius 6px
          .LeftCentnRanImgBox
            width 18px
            height 18px
            margin-left 10px
            margin-right 8px
          .RanKingText
            text-align center
            line-height 45px
            font-size 19px
            color #FFFFFF
        .LeftBootomText
          margin-left 23px
          margin-top 10px
          font-size 14px
          color #FFFFFF
    .ScorRigthBox
      margin-top 48px
      .ScorRingthBoxTop
        display flex
        justify-content space-between
        .MajorOne
          width 195px
          height 198px
          display flex
          flex-direction column
          align-items center
          .OneProgress
            position relative
            .text_box
              border 2px solid red
              border-radius 50%
              width 90px
              height 90px
              font-size 29px
              font-weight 500
              position absolute
              top 50%
              left 50%
              transform translate(-50%, -50%)
              .title
                text-align center
                line-height 80px
            .percent_box
              position relative
              text-align center
              width 200px
            .OneProgressText
              display flex
              flex-direction column
              align-items center
              justify-content center
              .Specialized
                font-size 21px
                font-weight 500
                color #383A4D
          .Textpercentage
            font-size 33px
            font-weight 500
            color #383A4D
            .Fen
              font-size 19px
              font-weight 500
              color #383A4D
        .MannerFour
          position relative
          width 195px
          height 198px
          background rgb(240, 242, 252)
          padding 15px 23px 0 23px
          .MannerFourTop
            width 148px
            border-bottom 1px dashed #878A99
            padding-bottom 15px
            display flex
            align-items center
            .TopLeftImg
              width 42px
              height 39px
              img
                width 100%
                height 100%
            .TopRigthBox
              margin-left 5px
              display flex
              flex-direction column
              align-items center
              .RigthNumber
                font-size 33px
                font-weight 500
                color #383A4D
              .RigthText
                font-size 14px
                font-weight 400
                color #383A4D
                opacity 0.6
          .Microphone
            position absolute
            bottom 0
            right 0
            width 74px
            height 74px
            img
              width 100%
              height 100%
      .MannerFuurBootom
        margin-top 8px
        .FuurTop
          display flex
          align-items center
          .progress-bar
            width 5px
            height 32px
            background-color #ddd
            position relative
          .progress
            background-color #666
            height 0%
            transition height 0.5s ease
            position absolute
            bottom 0
            width 100%
          .FuurTopRigth
            margin-left 8px
            .minute
              font-size 17px
              font-weight 500
              color #383A4D
            .speed
              font-size 15px
              font-weight 400
              color #383A4D
              opacity 0.6
        .FuurTwo
          margin-top 5px
      .ScorRingthBoxBottom
        margin-top 20px
        margin-left 38px
        margin-right 30px
        font-size 16px
        font-weight 400
        color #878A99
        line-height 29px
  .AbilityIndicator
    box-sizing border-box
    margin-top 19px
    width 1118px
    height auto
    border 1px solid #383A4D
    border-radius 8px
    padding 26px 90px 25px 35px
    .informationTop
      display flex
      align-items center
      margin-bottom 20px
      .mationImg
        width 15px
        height 15px
        margin-right 8px
        img
          width 100%
          height 100%
      .mationText
        font-size 21px
        font-weight bold
        color #383A4D
    .AbilityIndicatorBox
      display flex
      justify-content space-between
      .AbilityIndicatorLeft
        flex 1
        .CapabilityRadar
          text-align center
          font-size 19px
          font-weight bold
          color #383A4D
        .AbilityIndiBottom
          display flex
          justify-content space-around
          .YEllow
            display inline-block
            width 10px
            height 10px
            border 2px solid #F7D42D
            border-radius 50%
          .Skblue
            display inline-block
            width 10px
            height 10px
            border 2px solid #008AFF
            border-radius 50%
      .AbilityIndicatorRigth
        box-sizing border-box
        padding 40px 53px 0 50px
        width 478px
        height 421px
        background url('~@/assets/image/AIresume/框.png') no-repeat
        background-size 100% 100%
        .AbilityIndicatorRigthTop
          .Title
            font-size 19px
            font-weight bold
            color #383A4D
          .AbilityList
            display flex
            flex-wrap wrap
            margin-top 17px
            .ListEach
              margin-right 15px
              font-size 16px
              font-weight 400
              color #008AFF
              text-align center
              padding 8px 10px 8px 10px
              background rgb(224, 241, 255)
              margin-bottom 10px
              border-radius 3px
        .AbilityIndicatorRigthBootom
          margin-top 15px
          .Title
            font-size 19px
            font-weight bold
            color #383A4D
          .AbilityList
            display flex
            flex-wrap wrap
            margin-top 17px
            .ListEach
              margin-right 15px
              font-size 16px
              font-weight 400
              color #F93E39
              text-align center
              padding 8px 10px 8px 10px
              background rgb(254, 232, 231)
              margin-bottom 10px
              border-radius 3px
  .BootomFlexBox
    display flex
    justify-content space-between
    .OverallBestAward
      box-sizing border-box
      margin-top 19px
      width 549px
      height auto
      border 1px solid #383A4D
      border-radius 8px
      padding 26px 31px 25px 35px
      .informationTop
        display flex
        align-items center
        margin-bottom 20px
        .mationImg
          width 15px
          height 15px
          margin-right 8px
          img
            width 100%
            height 100%
        .mationText
          font-size 21px
          font-weight bold
          color #383A4D
      .OverallBestAwardList
        display flex
        justify-content space-between
        .OverallBestAwardListOne
          position relative
          width 108px
          height 172px
          background #FFFFFF
          border 1px solid #008AFF
          border-radius 3px
          .TopImg
            margin 25px 0px 10px 25px
            width 55px
            height 55px
          .TopText
            text-align center
            font-size 19px
            font-weight 500
            color #383A4D
          .percentage
            font-size 25px
            color #008AFF
            position absolute
            width 100%
            height 37px
            text-align center
            line-height 37px
            background rgb(224, 241, 255)
            border-radius 3px
            bottom 0
    .AbilityAnalysis
      box-sizing border-box
      margin-top 19px
      width 549px
      height auto
      border 1px solid #383A4D
      border-radius 8px
      padding 26px 31px 25px 35px
      .informationTop
        display flex
        align-items center
        margin-bottom 20px
        .mationImg
          width 15px
          height 15px
          margin-right 8px
          img
            width 100%
            height 100%
        .mationText
          font-size 21px
          font-weight bold
          color #383A4D
      .OverallBestAwardList
        display flex
        justify-content space-between
        .OverallBestAwardListOne
          position relative
          width 108px
          height 172px
          border-radius 3px
          .TopImg
            margin 25px 0px 10px 25px
            width 55px
            height 55px
          .TopText
            text-align center
            font-size 19px
            font-weight 500
            color #383A4D
          .percentage
            font-size 25px
            position absolute
            width 100%
            height 37px
            text-align center
            line-height 37px
            border-radius 3px
            bottom 0
.acticedBtn
  position fixed
  top 50%
  right 0
</style>
